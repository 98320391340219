'use client';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
// import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import style from './RequestFormMain.module.scss';
import { Icons } from '@/components/icons';
import Button from '@/components/ui/Button/Button';
import InputMask from 'react-input-mask';
import { sendForm } from '@/app/api/forms/sendForm';
import toast from 'react-hot-toast';

const formSchema = z.object({
  full_name: z.string().min(3),
  phone: z.string().min(3),
  email: z.string().email(),
  message: z.string().min(3),
});

type FormSchema = z.infer<typeof formSchema>;

const defaultValues = {
  full_name: '',
  phone: '',
  email: '',
  message: '',
};

interface Props {
  onClose?: () => void;
}

export const RequestFormMain = ({ onClose }: Props) => {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const onSubmit = async (data: FormSchema) => {
    try {
      await sendForm(data, { form_type: 'Contact us' });

      form.reset(defaultValues);
      onClose?.();

      toast.success('The form successfully submitted');
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong');
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={style.form}
        noValidate
      >
        <div className="mb-6 flex w-full flex-col-reverse flex-wrap items-end justify-between gap-10 md:flex-row md:flex-nowrap">
          <div className="w-full">
            {' '}
            <FormField
              control={form.control}
              name="full_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className={style.label} htmlFor="name">
                    {' '}
                    Name
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder={'Type your name'}
                      className={cn(
                        'md:w-96',
                        form.formState.errors.full_name && 'border-destructive',
                        'bg-white',
                        style.input
                      )}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="flex w-full gap-4 pb-2">
            <Icons.carIcon />
            <p className="text-left">
              <p className="text-left">
                We travel to you at{' '}
                <span style={{ color: ' rgba(31, 169, 151, 1)' }}>
                  {' '}
                  NO CHARGE
                </span>
              </p>
              <p className="text-left">
                for a free estimate on repair or replacement
              </p>
            </p>
          </div>
        </div>
        <div className="mb-6 flex w-full flex-wrap justify-between gap-10 md:flex-nowrap">
          <div className="w-full">
            {' '}
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className={style.label} htmlFor="phone">
                    {' '}
                    Phone
                  </FormLabel>

                  <FormControl>
                    <InputMask mask="(999) 999-9999" {...field}>
                      <Input
                        placeholder={'Type your phone'}
                        className={cn(
                          'md:w-96',
                          form.formState.errors.full_name &&
                            'border-destructive',
                          'bg-white',
                          style.input
                        )}
                      />
                    </InputMask>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="w-full">
            {' '}
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className={style.label} htmlFor="email">
                    {' '}
                    Email
                  </FormLabel>

                  <FormControl>
                    <Input
                      placeholder={'Type your email'}
                      type="email"
                      className={cn(
                        'md:w-96',
                        form.formState.errors.full_name && 'border-destructive',
                        'bg-white',
                        style.input
                      )}
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>

        <div className="mb-6 w-full">
          {' '}
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel className={style.label} htmlFor="message">
                  {' '}
                  Message
                </FormLabel>
                <FormControl style={{ width: '100%' }}>
                  <Textarea
                    style={{ minHeight: '198px' }}
                    placeholder={'Type your message'}
                    className={cn(
                      'md:w-96',
                      form.formState.errors.full_name && 'border-destructive',
                      'bg-white',
                      'w-full',
                      style.input
                    )}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <div className="text-left">
          <Button
            variant="main"
            shape="square"
            type="submit"
            disabled={form?.formState?.isSubmitSuccessful}
          >
            <p className="text-center text-[18px]">
              {form?.formState?.isSubmitSuccessful
                ? 'Form Submitted'
                : 'Send Message'}
            </p>
          </Button>
        </div>
      </form>
    </Form>
  );
};
