import { cn } from '@/lib/utils';
import React, { FC } from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'menu' | 'main' | 'cta' | 'secondary' | 'footer';
  shape?: 'square' | 'rounded';
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  shape = 'rounded',
  variant,
  onClick,
  children,
  disabled,
  ...props
}) => {
  const baseStyles = `font-poppins text-[18px] font-[400] leading-[28px] text-left
                      px-[28px] py-[12px] gap-[11px] 
                       transition-opacity duration-300
                      focus:outline-none`;

  const shapeStyles = shape === 'square' ? 'rounded-lg' : 'rounded-full';
  const variants = {
    menu: 'bg-gray-200 text-black hover:bg-gray-300',
    main: 'bg-black text-white hover:bg-gray-800',
    cta: 'bg-orange-600 text-white hover:bg-orange-700',
    secondary: 'bg-white text-black border border-black hover:bg-gray-100',
    footer: 'bg-orange-500 text-white hover:bg-orange-600 rounded-full',
  };

  return (
    <button
      className={cn(
        baseStyles,
        variants[variant],
        shapeStyles,
        disabled ? 'cursor-not-allowed opacity-50' : ''
      )}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
